@tailwind base;
@tailwind components;
@tailwind utilities;

/* * { border: 1px solid red; } */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components{
  .btn{
      @apply text-lg  laptop:text-lg  border-0 w-[300px] h-[44px] rounded-full shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150;
  }

  .footerText{
      @apply text-center text-white text-lg py-[15px] laptop:text-left desktop:text-left hover:text-deepOrange ease-linear transition-all duration-150;
  }

  .footerIcon{
      @apply mx-auto text-2xl w-full text-center items-center hover:opacity-[80%] text-background inline-block mx-3 hover:text-primary ease-linear transition-all duration-150;
  }
}

@layer base{
  a{
      @apply text-primary hover:text-primary ease-linear duration-150 hover:opacity-[80%] scroll-smooth;
  }
  h1{
      @apply scroll-smooth;
  }
  p{
      @apply scroll-smooth leading-relaxed;
  }
}

.hover-fill-text {
  color: transparent;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-image 0.5s ease;
}

.hover-fill-text:hover {
  background-image: linear-gradient(120deg, #96e6a1 0%, #d4fc79 100%);
}

#pete{
  scroll-behavior: smooth
}

